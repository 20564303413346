import React, { CSSProperties } from 'react'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Error from '../assets/Error'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import styled from 'styled-components'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'
import { EmailFilterStrategy } from '.'
import {
  ForbiddenInboxModal,
  PermittedInboxModal,
} from '../Integrations/google_workspace'
import { useEffect, useState } from 'react'
import Loading from '../assets/Loading'

export const completeMicrosoftSetup = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  variation: string,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  let url = window.location.href
  if (url.endsWith('#')) {
    url = url.slice(0, -1)
  }

  const params = new URLSearchParams(url.substring(url.indexOf('?')))
  // clear query string without refreshing the page. prevents double requests by state change
  window.history.replaceState({}, '', '/integrations')

  if (params.get('error')) {
    setModal(IntgModal.MicrosoftFail)
    return
  }
  const microsoftPath = addImpersonationParams(
    `/integrations/microsoft_365`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(microsoftPath, {
    method: 'POST',
    body: JSON.stringify({
      tenant_id: params.get('tenant'),
      state: params.get('state'),
      admin_consent: params.get('admin_consent'),
      variation: variation,
    }),
  })
  if (resp.error) {
    setModal(IntgModal.MicrosoftFail)
    return
  }
  setModal(IntgModal.MicrosoftSuccess)
}

const redirectToMicrosoftAuthUsers = async () => {
  const clientID = process.env.REACT_APP_MS365_USER_CLIENT_ID
  const redirectURI = process.env.REACT_APP_MS365_USER_REDIRECT_URI
  const state = `diminish_ms365_tYsHjd`
  window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${clientID}&state=${state}&redirect_uri=${redirectURI}`
}

const redirectToMicrosoftAuthUsersInboxes = async () => {
  const clientID = process.env.REACT_APP_MS365_USER_INBOX_CLIENT_ID
  const redirectURI = process.env.REACT_APP_MS365_USER_INBOX_REDIRECT_URI
  const state = `diminish_ms365_tYsHjd`
  window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${clientID}&state=${state}&redirect_uri=${redirectURI}`
}

const removeMicrosoftIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const microsoftPath = addImpersonationParams(
    `/integrations/microsoft_365`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )
  const resp = await backendRequest(microsoftPath, { method: 'DELETE' })
  if (resp.error) {
    setModal(IntgModal.MicrosoftFail)
    return
  }
  setModal(0)
}

export const MicrosoftModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()
  switch (modal) {
    case IntgModal.MicrosoftRedirect:
      return <MicrosoftRedirectModal onDismiss={onDismiss} />
    case IntgModal.MicrosoftSuccess:
      return <MicrosoftSuccessModal onDismiss={onDismiss} />
    case IntgModal.MicrosoftFail:
      return <MicrosoftFailModal onDismiss={onDismiss} />
    case IntgModal.MicrosoftRemove:
      return (
        <MicrosoftRemoveModal
          onDismiss={onDismiss}
          onClick={() => {
            removeMicrosoftIntegration(
              setModal,
              impersonate,
              reqOrgId,
              reqUserId
            )
          }}
        />
      )
    case IntgModal.MicrosoftSettings:
      return <MicrosoftSettingsModal onDismiss={onDismiss} />
    default:
      return <></>
  }
}

export const MicrosoftSettingsModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  const [filteringStrategy, setFilteringStrategy] = useState<
    undefined | null | string
  >()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (filteringStrategy !== undefined) {
      return
    }
    const getFilteringStrategy = async () => {
      const strategy = addImpersonationParams(
        `/contracts/filtering_strategy`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(strategy)
      if (resp.error) {
        setFilteringStrategy(null)
        return
      }
      setFilteringStrategy(resp.filtering_strategy)
    }
    getFilteringStrategy()
  }, [impersonate, reqOrgId, reqUserId, filteringStrategy])

  return (
    <Modal
      onDismiss={onDismiss}
      style={{ width: '460px', borderColor: theme.color.textPurple }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <HeadingMedium color="textDefault">
            Microsoft 365 Configuration
          </HeadingMedium>
        </div>
        <Divider />
        {!filteringStrategy ? (
          <Loading />
        ) : filteringStrategy === EmailFilterStrategy.FORBIDDEN_INBOXES ? (
          <ForbiddenInboxModal onDismiss={onDismiss} />
        ) : filteringStrategy === EmailFilterStrategy.PERMITTED_INBOXES ? (
          <PermittedInboxModal onDismiss={onDismiss} />
        ) : filteringStrategy === EmailFilterStrategy.NO_FILTER ? (
          <>
            No inboxes are currently being filtered for your account. Contact
            Diminish support to set it up.
          </>
        ) : filteringStrategy === EmailFilterStrategy.DISABLED_INBOXES ? (
          <>
            Inbox access is not enabled for your organization. Contact Diminish
            support to set it up.
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  )
}

const Highlighted = styled('span')`
  color: ${theme.color.errorRed};
`

export const MicrosoftRedirectModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <MicrosoftModalRedirect
      onDismiss={onDismiss}
      onClickUsers={redirectToMicrosoftAuthUsers}
      onClickUsersInboxes={redirectToMicrosoftAuthUsersInboxes}
      canCancel={true}
      heading="Microsoft 365 Installation"
      body={
        <>
          <p>
            To install Microsoft 365, it is necessary to have either{' '}
            <Highlighted>
              Global Administrator or Privileged Role Administrator{' '}
            </Highlighted>
            privileges for your company's Microsoft Entra tenant.
          </p>
          <p>
            We will redirect you to Microsoft Entra to complete the next step
            for integrating with Diminish.
          </p>
        </>
      }
      color="textPurple"
    />
  )
}

export const MicrosoftSuccessModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <MicrosoftModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Congratulations"
      body={'You have successfully integrated with Microsoft 365.'}
      buttonTxt="Ok"
      Icon={GreenCheckCircle}
      color="successGreen"
    />
  )
}

export const MicrosoftFailModal = ({
  onDismiss,
}: {
  onDismiss: () => void
}) => {
  return (
    <MicrosoftModal
      onDismiss={onDismiss}
      onClick={onDismiss}
      canCancel={false}
      heading="Something went wrong"
      body={'Please try again later or contact support'}
      buttonTxt="Ok"
      Icon={Error}
      color="errorRed"
    />
  )
}

export const MicrosoftRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <MicrosoftModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove this integration? By removing your Microsoft integration, workspace and contract sync will stop working.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}

const MicrosoftModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string | JSX.Element
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '460px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)

const MicrosoftModalRedirect = ({
  onDismiss,
  onClickUsers,
  onClickUsersInboxes,
  canCancel,
  heading,
  body,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClickUsers: () => Promise<void> | void
  onClickUsersInboxes: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string | JSX.Element
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '460px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}

        <Button
          onClick={onClickUsers}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          User Sync Only
        </Button>

        <Button
          onClick={onClickUsersInboxes}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          Users and Invoice Emails Sync
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
