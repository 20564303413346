import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../assets/Loading'
import Modal from '../components/Modal'
import theme from '../Theme'

import {
  EmptyTableBody,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from '../components/Table'
import { HeadingMedium } from '../components/Text'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import Archive from '../assets/Archive'
import Paper from '../assets/Paper'
import Pencil from '../assets/Pencil'
import {
  ContractEdit,
  ContractModal,
  ContractModalType,
} from '../Contracts/ContractModal'
import { Tooltip } from 'react-tooltip'

type FileWithContract = {
  contract_id: number
  seats: number
  contract_value: number
  app_name: string
  plan_name: string
  start_date: string
  end_date: string
  billing_cycle: string
  sender_email_addr: string
  receiver_email: string
  presigned_url: string
  document_type: string[]
  owner: string | null
}

export const LatestInvoicesTable = () => {
  const [invoices, setInvoices] = useState<FileWithContract[] | null>(null)
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false)
  const [invoiceURL, setInvoiceURL] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [contractEdit, setContractEdit] = useState<ContractEdit | null>(null)
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  let { product_id } = useParams()

  useEffect(() => {
    const getInvoices = async () => {
      if (invoices !== null) {
        return
      }
      const reqPath = addImpersonationParams(
        `/products/invoices/${product_id}`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch invoices`, ToastType.ERROR)
        setInvoices([])
        return
      }
      setInvoices(resp)
    }
    getInvoices()
  }, [product_id, invoices, reqOrgId, reqUserId, impersonate])

  if (invoices === null) {
    return <Loading />
  }

  const handleContractEdit = (contract_id: number) => {
    let contract = invoices.find((c) => c.contract_id === contract_id)
    if (contract) {
      setContractEdit({
        contract_id: contract.contract_id,
        application_name: contract.app_name,
        owner_email: contract.owner || '',
        plan_name: contract.plan_name,
        billing_cycle: contract.billing_cycle,
        contract_value: contract.contract_value,
        start_date: contract.start_date,
        end_date: contract.end_date,
        num_seats: contract.seats,
        document_type: contract.document_type,
      })
      setShowModal(true)
    }
  }

  const handlePDFPreview = (invoice_url: string) => {
    setInvoiceURL(invoice_url)
    setShowPDFPreviewModal(true)
  }

  const handleArchive = async (contract_id: number) => {
    const contractArchivePath = addImpersonationParams(
      `/contracts/archive`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )
    const resp = await backendRequest(contractArchivePath, {
      method: 'POST',
      body: JSON.stringify({
        contract_id,
      }),
    })

    if (resp.error) {
      showToast(
        `Failed to archive contract. Please try again later.`,
        ToastType.ERROR
      )
      return
    }
    showToast(`Contract successfully archived`, ToastType.SUCCESS)
    const updatedContracts = invoices.filter(
      (contract) => contract.contract_id !== contract_id
    )
    setInvoices(updatedContracts)
  }

  const contractEditCallback = () => {
    setShowModal(false)
    setInvoices(null)
  }

  return (
    <>
      {showPDFPreviewModal !== false && (
        <PDFPreviewModal
          onDismiss={() => setShowPDFPreviewModal(false)}
          invoice_url={invoiceURL}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '24px 0 16px',
        }}
      >
        <HeadingMedium color="textDefault" style={{ marginBottom: '15px' }}>
          Latest Invoices
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {invoices.length > 0 && ` (${invoices.length})`}
          </span>
        </HeadingMedium>
      </div>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>Plan name</TableCell>
            <TableCell>Contract Value</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Receiver Email</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.length === 0 ? (
            <EmptyTableBody colSpan={6} />
          ) : (
            invoices.map((invoice, i) => (
              <TableRow key={i}>
                <TableCell>{invoice.plan_name || '-'}</TableCell>
                <TableCell>
                  {currencyFormat(invoice.contract_value) || '-'}
                </TableCell>
                <TableCell>{invoice.start_date || '-'}</TableCell>
                <TableCell>{invoice.end_date || '-'}</TableCell>
                <TableCell>{invoice.receiver_email || '-'}</TableCell>
                <TableCell>
                  <Pencil
                    className="edit-icon"
                    onClick={() => handleContractEdit(invoice.contract_id)}
                  />
                  {invoice.presigned_url ? (
                    <Paper
                      style={{ marginLeft: '12px' }}
                      className="preview-icon"
                      onClick={() => handlePDFPreview(invoice.presigned_url)}
                    />
                  ) : null}
                  <Archive
                    className="archive-icon"
                    style={{ marginLeft: '12px' }}
                    onClick={() => handleArchive(invoice.contract_id)}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
      {showModal && (
        <ContractModal
          modalType={ContractModalType.EDIT}
          contractEdit={contractEdit}
          onDismiss={() => setShowModal(false)}
          contractEditCallback={() => contractEditCallback()}
        />
      )}
      <Tooltip anchorSelect=".edit-icon" place="top" style={{ zIndex: 10 }}>
        Edit Contract
      </Tooltip>
      <Tooltip anchorSelect=".preview-icon" place="top" style={{ zIndex: 10 }}>
        Preview Contract
      </Tooltip>
      <Tooltip anchorSelect=".archive-icon" place="top" style={{ zIndex: 10 }}>
        Archive Contract
      </Tooltip>
    </>
  )
}

export const PDFPreviewModal = ({
  invoice_url,
  onDismiss,
}: {
  invoice_url: string
  onDismiss: () => void
}) => {
  return (
    <Modal
      onDismiss={onDismiss}
      style={{
        width: '900px',
        height: '900px',
        borderColor: theme.color.backgroundPurple,
      }}
    >
      {invoice_url === '' ? (
        <Loading />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <object
              id="invoice_pdf"
              data={invoice_url}
              height="870"
              width="830"
            >
              <p> Link expired, please reload the page. </p>
            </object>
          </div>
        </div>
      )}
    </Modal>
  )
}
