import styled from 'styled-components'
import { MainCard, TotalSavingsCard } from '../components/Cards'
import { HeadingLarge, HeadingMedium } from '../components/Text'
import useAuth from '../hooks/useAuth'
import useImpersonation from '../hooks/useImpersonation'
import {
  getThisMonth,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ApplicationsTable } from './ApplicationsTable'
import { MainGraph } from './MainGraph'

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
const MainPaneWrapper = styled('div')`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-bottom: 8px;
`
const LeftCards = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Overview = () => {
  const { user } = useAuth()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  const getExpenseSummary = async () => {
    const reqPath = addImpersonationParams(
      `/summary/expenses`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    return backendRequest(reqPath)
  }

  const getUsageTotals = async () => {
    const reqPath = addImpersonationParams(
      `/usage/totals`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    return backendRequest(reqPath)
  }

  const getTimeOfDay = () => {
    const now = new Date()
    const currentHour = now.getHours()
    if (currentHour < 12) {
      return 'Morning'
    } else if (currentHour < 18) {
      return 'Afternoon'
    } else {
      return 'Evening'
    }
  }

  const userName = (user && user.first_name) || ''
  return (
    <>
      <HeadingWrapper>
        <HeadingLarge>
          Good {getTimeOfDay()}
          {userName ? `, ${userName}` : ''}
        </HeadingLarge>
        <HeadingMedium>Last Update: {getThisMonth()}</HeadingMedium>
      </HeadingWrapper>
      <MainPaneWrapper>
        <LeftCards>
          <MainCard getExpenseSummary={getExpenseSummary} />
          <TotalSavingsCard getUsageTotals={getUsageTotals} />
        </LeftCards>
        <MainGraph />
      </MainPaneWrapper>
      <ApplicationsTable />
    </>
  )
}

export default Overview
