import React, { CSSProperties } from 'react'
import Warning from '../assets/Warning'
import { IntgModal } from '.'
import Button, { GhostButton } from '../components/Button'
import Divider from '../components/Divider'
import Modal, { ButtonWrapper } from '../components/Modal'
import { HeadingMedium, HeadingSmallest } from '../components/Text'
import theme from '../Theme'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import useImpersonation from '../hooks/useImpersonation'

const removeNetsuiteIntegration = async (
  setModal: (value: React.SetStateAction<IntgModal>) => void,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number
) => {
  const netsuitePath = addImpersonationParams(
    `/integrations/netsuite`,
    impersonate,
    reqOrgId,
    reqUserId,
    false
  )

  const resp = await backendRequest(netsuitePath, {
    method: 'DELETE',
  })
  if (resp.error) {
    setModal(IntgModal.NetsuiteFail)
    return
  }
  setModal(0)
}

export const NetsuiteModalHandler = ({
  modal,
  onDismiss,
  setModal,
}: {
  modal: IntgModal
  onDismiss: () => void
  setModal: (value: React.SetStateAction<IntgModal>) => void
}) => {
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  switch (modal) {
    case IntgModal.NetsuiteRemove:
      return (
        <NetsuiteRemoveModal
          onDismiss={onDismiss}
          onClick={() => {
            removeNetsuiteIntegration(
              setModal,
              impersonate,
              reqOrgId,
              reqUserId
            )
          }}
        />
      )
    default:
      return <></>
  }
}

export const NetsuiteRemoveModal = ({
  onDismiss,
  onClick,
}: {
  onDismiss: () => void
  onClick: () => void
}) => {
  return (
    <NetsuiteModal
      onDismiss={onDismiss}
      onClick={onClick}
      canCancel={true}
      heading="Remove Integration"
      body={
        'Are you sure you want to remove this integration? By removing your NetSuite integration, SaaS spend data will be removed from the dashboard.'
      }
      buttonTxt="Remove"
      Icon={Warning}
      color="errorRed"
    />
  )
}

const NetsuiteModal = ({
  onDismiss,
  onClick,
  canCancel,
  heading,
  body,
  buttonTxt,
  Icon,
  color,
}: {
  onDismiss: () => void
  onClick: () => Promise<void> | void
  canCancel: boolean
  heading: string
  body: string
  buttonTxt: string
  Icon?: ({ style }: { style: CSSProperties }) => JSX.Element
  color: keyof typeof theme.color
}) => (
  <Modal
    onDismiss={onDismiss}
    style={{ width: '350px', borderColor: theme.color[color] }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        {Icon ? <Icon style={{ height: '24px', width: '24px' }} /> : <></>}
        <HeadingMedium color="textDefault">{heading}</HeadingMedium>
      </div>
      <Divider />
      <HeadingSmallest>{body}</HeadingSmallest>
      <Divider />
      <ButtonWrapper>
        {canCancel && (
          <GhostButton
            onClick={onDismiss}
            style={{ padding: '14px 24px', borderColor: theme.color[color] }}
          >
            Cancel
          </GhostButton>
        )}
        <Button
          onClick={onClick}
          style={{
            backgroundColor: theme.color[color],
            border: theme.color[color],
          }}
        >
          {buttonTxt}
        </Button>
      </ButtonWrapper>
    </div>
  </Modal>
)
