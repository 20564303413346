import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ContractsArchiveEmpty from '../assets/ContractsArchiveEmpty'
import Loading from '../assets/Loading'
import {
  ApplicationCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  UserCell,
} from '../components/Table'
import { HeadingMedium, HeadingSmall } from '../components/Text'
import theme from '../Theme'
import {
  capitalize,
  currencyFormat,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { ContractList, Contracts } from '.'

const ArchivedContractsPage = () => {
  const [contracts, setContracts] = useState<Contracts | null>()
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (contracts) {
      return
    }
    const getContracts = async () => {
      const reqPath = addImpersonationParams(
        `/contracts`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          `Failed to fetch contracts. Please try again later.`,
          ToastType.ERROR
        )
        setContracts(null)
        return
      }
      setContracts(resp)
    }
    getContracts()
  }, [contracts, reqOrgId, reqUserId, impersonate])

  if (contracts === undefined) {
    return <Loading large />
  }

  return (
    <>
      <ArchivedContracts
        contractList={contracts?.archived_contracts || []}
        numContracts={contracts?.num_archived || 0}
      />
    </>
  )
}

const EmptyStateWrapper = styled('div')({
  borderTop: `1px solid ${theme.color.border}`,
  borderBottom: `1px solid ${theme.color.border}`,
  margin: '15px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '8px',
})

const ArchivedContractEmptyState = () => (
  <TableRow>
    <TableCell colSpan={8}>
      <EmptyStateWrapper>
        <ContractsArchiveEmpty />
        <HeadingSmall color="textDefault">
          You have no archived contracts yet!
        </HeadingSmall>
      </EmptyStateWrapper>
    </TableCell>
  </TableRow>
)

export const ArchivedContracts = ({
  contractList,
  numContracts,
}: {
  contractList: ContractList
  numContracts: number
}) => {
  const contracts = contractList

  return (
    <>
      <HeadingMedium style={{ padding: '24px 0' }} color="textDefault">
        Archived Contracts
        <span style={{ fontWeight: '400', fontSize: '14px' }}>
          {` (${numContracts})`}
        </span>
      </HeadingMedium>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell>Application</TableCell>
            <TableCell>Plan Name</TableCell>
            <TableCell w="200px">Owner</TableCell>
            <TableCell>Contract Value</TableCell>
            <TableCell>Archived Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(contracts).length === 0 ? (
            <ArchivedContractEmptyState />
          ) : (
            Object.values(contracts).map((ctrcs, i) => {
              return ctrcs.map((ctrc, i) => (
                <TableRow key={i}>
                  <ApplicationCell
                    id={ctrc.saas_id}
                    logo={ctrc.logo_url}
                    name={ctrc.name}
                  />
                  <TableCell>{capitalize(ctrc.plan_name)}</TableCell>
                  <UserCell
                    hash={ctrc.owner_hash}
                    email={ctrc.owner}
                    profile_picture={ctrc.profile_picture}
                  />
                  <TableCell>${currencyFormat(ctrc.contract_value)}</TableCell>
                  <TableCell>{ctrc.archived_date}</TableCell>
                </TableRow>
              ))
            })
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}

export default ArchivedContractsPage
