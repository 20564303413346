import LogoWithText from '../assets/logos/LogoWithText'
import styled from 'styled-components'
import { ProfileDropdown } from './Profile'
import theme from '../Theme'
import useAuth from '../hooks/useAuth'
import useImpersonation from '../hooks/useImpersonation'

const PageHeader = styled('div')({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.color.backgroudDefault,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.color.border}`,
  padding: '16px 70px',
})

const NavWrapper = styled('div')`
  display: flex;
  gap: 56px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`

const NavButton = styled('a')<{ isActive: boolean }>`
  text-decoration: none;
  color: ${({ theme }) => theme.color.textDefault};
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  border-bottom: ${({ isActive }) =>
    `2px solid ${
      isActive ? theme.color.textPurple : theme.color.backgroudDefault
    }`};
`

const isActive = (path: string): boolean => {
  if (path === '/') {
    return path === window.location.pathname
  }
  return window.location.pathname.includes(path)
}

const NavBar = () => {
  const { user, permissions, logout } = useAuth()
  const { reqOrgName, reqUserEmail, impersonate } = useImpersonation()

  const showContractsSubNav = (): boolean => {
    return window.location.href.includes('/contracts')
  }

  return (
    <>
      <PageHeader>
        <NavWrapper>
          <a style={{ height: '34px' }} href="/">
            <LogoWithText />
          </a>
          {permissions && permissions.see_usage && (
            <NavButton isActive={isActive('/')} href="/">
              Overview
            </NavButton>
          )}
          {permissions && permissions.see_users && (
            <NavButton isActive={isActive('/users')} href="/users">
              Users
            </NavButton>
          )}
          {permissions && permissions.see_integrations && (
            <NavButton
              isActive={isActive('/integrations')}
              href="/integrations"
            >
              Integrations
            </NavButton>
          )}

          {permissions && permissions.see_contracts && (
            <NavButton
              isActive={isActive('/contracts')}
              href="/contracts/active"
            >
              Contracts
            </NavButton>
          )}

          {permissions && permissions.see_reports && (
            <NavButton isActive={isActive('/insights')} href="/insights">
              Insights
            </NavButton>
          )}

          {impersonate && user && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.color.backgroundSubdued,
              }}
            >
              <label
                style={{
                  color: theme.color.textError,
                  fontSize: '16px',
                  lineHeight: '25px',
                  fontWeight: 'bold',
                }}
              >
                Impersonating Org: {reqOrgName}, User: {reqUserEmail}
              </label>
            </div>
          )}
        </NavWrapper>

        {user && (
          <ProfileDropdown
            logout={logout}
            size="medium"
            name={user.first_name || user.email}
            src={user.profile_picture}
          />
        )}
      </PageHeader>
      {showContractsSubNav() && user && (
        <div
          style={{
            display: 'flex',
            gap: '56px',
            height: '56px',
            backgroundColor: theme.color.backgroundSubdued,
            alignItems: 'flex-end',
            padding: '0 70px',
          }}
        >
          <NavButton
            href={'/contracts/active'}
            isActive={isActive('/contracts/active')}
          >
            <span style={{ lineHeight: '36px' }}>{'Active Contracts'}</span>
          </NavButton>
          <NavButton
            href={'/contracts/upcoming'}
            isActive={isActive('/contracts/upcoming')}
          >
            <span style={{ lineHeight: '36px' }}>{'Upcoming Renewals'}</span>
          </NavButton>
          <NavButton
            href={'/contracts/archive'}
            isActive={isActive('/contracts/archive')}
          >
            <span style={{ lineHeight: '36px' }}>{'Archive'}</span>
          </NavButton>
        </div>
      )}
    </>
  )
}

export default NavBar
