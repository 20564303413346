import { Budget } from '../Overview/ApplicationsTable'

export const backendRequest = async (path: string, payload?: any) => {
  try {
    const app = await fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}${path}`, {
      ...payload,
      credentials: 'include',
    })
    if (app.status === 401) {
      window.location.href = `/login?redirect=${window.location.pathname}`
    }
    if (app.status === 504) {
      return { error: 'Gateway Timeout' }
    }
    if (app.status >= 500) {
      return { error: 'Server Error' }
    }
    return await app.json()
  } catch (err) {
    console.error(`Failed to fetch ${path}`)
    return { error: err }
  }
}

export const addImpersonationParams = (
  url: string,
  impersonate: boolean,
  reqOrgId: number,
  reqUserId: number,
  urlHasParam: boolean
): string => {
  if (impersonate && reqOrgId !== -1 && reqUserId !== -1) {
    if (urlHasParam) {
      return `${url}&req_org_id=${reqOrgId}&req_user_id=${reqUserId}&impersonate=true`
    } else {
      return `${url}?req_org_id=${reqOrgId}&req_user_id=${reqUserId}&impersonate=true`
    }
  }

  return url
}

/**
 * takes 123456.789 and returns "123,456.79"
 * @param amount dollar amount as a number
 * @returns currency formatted as a string ##,###.##
 */
export const currencyFormat = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}
/**
 * @returns string formatted current month and year (e.g. Jan 2023)
 */
export const getThisMonth = (): string => {
  const date = new Date()
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(date)
}
/**
 * @returns string formatted this month and year (e.g. Jan 2023)
 */
export const getThisMonthShort = (): string => {
  const date = new Date()
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  }).format(date)
}
/**
 * takes a date string and returns a string formatted as `Jan 2023`
 * Safari and Firefox do not support 'October 2023' date format, so additional logic is required
 * @returns string formatted this month and year (e.g. Jan 2023)
 */
export const getMonthShort = (d: string): string => {
  const dateParts = d.split(' ')
  if (dateParts.length === 2) {
    const month = capitalize(dateParts[0].slice(0, 3))
    return `${month} ${dateParts[1]}`
  }
  const date = new Date(d)
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    month: 'short',
    year: 'numeric',
  }).format(date)
}
/**
 * @returns string formatted Jan of this year (e.g. Jan 2023)
 */
export const getStartOfYear = (): string => {
  const date = new Date()
  date.setMonth(0)
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  }).format(date)
}
/**
 * @returns string formatted last month and year (e.g. Dec 2022)
 */
export const getLastMonth = (): string => {
  const date = new Date()
  date.setDate(0)
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  }).format(date)
}

/**
 * capitalize only first letter of a string
 * @param word {string} (e.g. 'captain jack sparrow')
 * @returns {string} 'Captain jack sparrow'
 */
export const capitalize = (word: string | undefined | null) => {
  if (!word) {
    return ''
  }
  return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

export const getPastNMonths = (n: number) => {
  const months: string[] = []
  const date = new Date()
  months.push(
    new Intl.DateTimeFormat('default', {
      month: 'long',
      year: 'numeric',
    }).format(date)
  )

  for (let i = 0; i < n - 1; i++) {
    date.setDate(0)
    months.push(
      new Intl.DateTimeFormat('default', {
        month: 'long',
        year: 'numeric',
      }).format(date)
    )
  }

  return months
}

export const getPastNQuarters = (n: number) => {
  const quarters: string[] = []
  const date = new Date()
  let quarter = Math.floor(date.getMonth() / 3)
  let year = date.getFullYear()

  for (let i = 0; i < n; i++) {
    let monthRange = ''
    if (quarter === 0) {
      monthRange = '(Jan - Mar)'
    } else if (quarter === 1) {
      monthRange = '(Apr - Jun)'
    } else if (quarter === 2) {
      monthRange = '(Jul - Sep)'
    } else if (quarter === 3) {
      monthRange = '(Oct - Dec)'
    }
    quarters.push(`Q${quarter + 1} ${year} ${monthRange}`)

    if (quarter === 0) {
      quarter = 3
      year--
    } else {
      quarter--
    }
  }
  return quarters
}

// returns the number of days that two timestamps are apart
// use Date().getTime() to get the timestamp
export const getDateDiff = (d1: Date, d2: Date) => {
  const diff = d1.getTime() - d2.getTime()
  return diff / (1000 * 60 * 60 * 24)
}

// Takes a Date string and returns in `MMM DD, YYYY` format
export const dateToShortFormat = (d: string) => {
  if (d.length === 0) return ''
  return new Date(d).toLocaleString('en-CA', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

export const urlSafeName = (name: string) => {
  return name.toLocaleLowerCase().replaceAll(' ', '-')
}

// takes date in MMM YYYY format and returns YYYY-MM-DD
export const getSortableDate = (dt: string): string => {
  const date = new Date(dt)
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = date.getDate()
  if (year && month && day) {
    return `${year}-${month}-${day}`
  }
  return ''
}

export const getRangeBudget = (
  dateFilter: string,
  budget: Budget | undefined
) => {
  if (!budget) {
    return 0
  }
  const startDate = new Date(budget.budget_start_date)
  const endDate = new Date(budget.budget_end_date)
  const diff =
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    endDate.getMonth() -
    startDate.getMonth()

  const monthlyBudget = budget.amount / diff

  const quarters = getPastNQuarters(5)
  const months = getPastNMonths(15)

  if (quarters.includes(dateFilter) || dateFilter.includes('90')) {
    return monthlyBudget * 3
  }
  if (months.includes(dateFilter) || dateFilter.includes('30')) {
    return monthlyBudget
  }
  if (dateFilter.includes('60')) {
    return monthlyBudget * 2
  }
  return monthlyBudget * 12
}
