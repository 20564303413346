import styled, { CSSProperties } from 'styled-components'
import theme from '../Theme'

export const GhostButton = styled('button')<{
  disabled?: boolean
  style?: CSSProperties
}>`
  height: 48px;
  color: ${({ disabled }) =>
    disabled ? theme.color.btnTxtDisabled : theme.color.textDefault};
  background-color: ${({ disabled }) =>
    disabled ? theme.color.btnBgDisabled : theme.color.white};
  border-radius: 4px;
  padding: 12px 30px;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? theme.color.btnBgDisabled : theme.color.border};
  &:hover {
    ${({ disabled }) =>
      disabled ? '' : `border-color: ${theme.color.textPurple} !important;`}
  }
  /* layout for Icon + text */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  ${({ style }) => {
    return { ...style }
  }};
`
const Button = styled('button')<{
  disabled?: boolean
  style?: CSSProperties
}>`
  height: 48px;
  color: ${({ disabled }) =>
    disabled ? theme.color.btnTxtDisabled : theme.color.white};
  background-color: ${({ disabled }) =>
    disabled ? theme.color.btnBgDisabled : theme.color.textPurple};
  border-radius: 4px;
  padding: 12px 30px;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? theme.color.btnBgDisabled : theme.color.textPurple};
  /* layout for Icon + text */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  ${({ style }) => {
    return { ...style }
  }};
`
export const ButtonSubdued = styled('button')<{
  style?: CSSProperties
}>`
  height: 48px;
  color: ${theme.color.textDefault};
  background-color: ${theme.color.backgroundSubdued};
  border-radius: 4px;
  padding: 12px 30px;
  border: 1px solid ${theme.color.textPurple};
  /* layout for Icon + text */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &:hover {
    color: ${theme.color.white};
    background-color: ${theme.color.textPurple};
  }
  ${({ style }) => {
    return { ...style }
  }};
`

export default Button
