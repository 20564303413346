import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import {
  HeadingLarge,
  HeadingMedium,
  HeadingSmallest,
} from '../components/Text'
import { Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  LineController,
  BarController,
  PointElement,
  LineElement,
} from 'chart.js'
import theme from '../Theme'
import BarChartEmpty from '../assets/BarChartEmpty'
import Loading from '../assets/Loading'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'

// Bar Chart Docs : https://www.chartjs.org/docs/latest/charts/bar.html

ChartJS.register(
  CategoryScale,
  LineController,
  BarController,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
)

export const MainGraph = () => {
  const [dataset, setDataset] = useState<any>()
  const { impersonate, reqOrgId, reqUserId } = useImpersonation()

  useEffect(() => {
    if (dataset) {
      return
    }

    const getExpenses = async () => {
      const reqPath = addImpersonationParams(
        `/expenses/history`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        if (!resp.error.includes('integration not found')) {
          showToast(`Failed to fetch expense history`, ToastType.ERROR)
        }
        setDataset(null)
        return
      }
      setDataset(resp.history)
    }
    getExpenses()
  }, [dataset, reqOrgId, reqUserId, impersonate])

  return (
    <>
      {dataset === undefined ? (
        <GraphWrapper>
          <Loading />
        </GraphWrapper>
      ) : dataset === null || Object.keys(dataset).length === 0 ? (
        <EmptyGraph />
      ) : (
        <StyledBarChart
          title="Spending Overview"
          subTitle="Last 12 months"
          toolTip="Spend"
          data={dataset}
        />
      )}
    </>
  )
}

const EmptyGraphWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.color.borderDisabled};
  border-radius: 8px;
  height: 437px;
  min-width: 803px;
  background-color: ${theme.color.backgroundSubdued};
`

export const EmptyGraph = () => (
  <EmptyGraphWrapper>
    <BarChartEmpty />
    <HeadingLarge style={{ marginBottom: '16px' }}>
      This is where you see your spending Overview
    </HeadingLarge>
    <HeadingSmallest style={{ marginBottom: '24px' }}>
      Integrate your financial data and generate spend reports{' '}
    </HeadingSmallest>
    <Button onClick={() => (window.location.href = '/integrations')}>
      Integrate Your Financial Data
    </Button>
  </EmptyGraphWrapper>
)

export const GraphWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${theme.color.border};
  border-radius: 8px;
  height: 390px;
  min-width: 803px;
  background-color: ${theme.color.white};
  padding: 24px;
`

const graphOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // removes horizontal lines between bars
        offset: true,
      },
    },
    y: {
      position: 'left' as const,
    },
  },
  interaction: {},
}

export const StyledBarChart = ({
  title,
  subTitle,
  toolTip,
  data,
}: {
  title?: string
  subTitle?: string
  toolTip?: string
  data: any
}) => {
  const chartRef = useRef()

  const numBars = Object.keys(data).length
  const graphData: GraphData = {
    labels: Object.keys(data).map(dateAbbrev),
    datasets: [
      {
        label: toolTip || '',
        data: Object.values(data),
        backgroundColor: [
          ...Array(numBars - 1).fill(theme.color.graphBarDefault),
          theme.color.textPurple,
        ],
        hoverBackgroundColor: theme.color.graphBarHover,
        borderRadius: 4,
        barThickness: 34,
        categoryPercentage: 0.9,
        minBarLength: 10,
      },
    ],
  }

  const onClick = (event: any) => {
    if (chartRef.current === undefined) {
      return
    }
    // TODO: update other elements in the page based on what bar was clicked in the chart
    // console.log(getElementAtEvent(chartRef.current, event));
  }

  return (
    <GraphWrapper>
      {title ? (
        <HeadingMedium style={{ marginBottom: '8px', fontSize: '20px' }}>
          {title}
        </HeadingMedium>
      ) : (
        <></>
      )}
      {subTitle ? (
        <HeadingSmallest color="textDefault" style={{ marginBottom: '18px' }}>
          {subTitle}
        </HeadingSmallest>
      ) : (
        <></>
      )}
      <div style={{ flexGrow: 1, width: '100%' }}>
        <Chart
          type="bar"
          ref={chartRef}
          options={graphOptions}
          data={graphData}
          onClick={onClick}
        />
      </div>
    </GraphWrapper>
  )
}

export const StyledDualBarChart = ({
  title,
  subTitle,
  dataset1,
  dataset2,
  dataset3,
}: {
  title?: string
  subTitle?: string
  dataset1: { legend: string; data: { [k: string]: number } }
  dataset2: { legend: string; data: { [k: string]: number } }
  dataset3?: { legend: string; data: { [k: string]: number } }
}) => {
  const chartRef = useRef()
  const defaultDataset = {
    borderRadius: 4,
    barThickness: 24,
    categoryPercentage: 0.9,
    minBarLength: 0,
  }
  const graphData: GraphData = {
    labels: Object.keys(dataset1.data).map(dateAbbrev),
    datasets: [
      {
        id: 3,
        type: 'line',
        label: dataset3 ? dataset3.legend : '',
        data: dataset3 ? Object.values(dataset3.data) : [],
        backgroundColor: theme.color.graphLineBlue,
        hoverBackgroundColor: theme.color.graphLineBlue,
        borderColor: theme.color.graphLineBlue,
        yAxisID: 'y2',
        ...defaultDataset,
      },
      {
        id: 1,
        label: dataset1.legend,
        data: Object.values(dataset1.data),
        backgroundColor: theme.color.graphPurple,
        hoverBackgroundColor: theme.color.graphPurpleHover,
        yAxisID: 'y',
        ...defaultDataset,
      },
      {
        id: 2,
        label: dataset2.legend,
        data: Object.values(dataset2.data),
        backgroundColor: Object.values(dataset2.data).every((v) => v === 0)
          ? theme.color.graphBarDisabled
          : theme.color.graphGreen,
        hoverBackgroundColor: theme.color.graphGreenHover,
        yAxisID: 'y1',
        ...defaultDataset,
      },
    ],
  }

  const opt = {
    ...graphOptions,
    plugins: {
      ...graphOptions.plugins,
      legend: {
        display: true,
        align: 'end' as const,
        labels: {
          boxHeight: 24,
          boxWidth: 24,
          useBorderRadius: true,
          borderRadius: 2,
        },
      },
    },
    scales: {
      ...graphOptions.scales,
      y1: {
        grid: {
          display: false, // removes horizontal lines between bars
          offset: true,
        },
        position: 'right' as const,
      },
      y2: {
        display: false,
        grid: {
          display: false,
        },
        suggestedMax: 10,
      },
    },
  }

  const onClick = (event: any) => {
    if (chartRef.current === undefined) {
      return
    }
    // TODO: update other elements in the page based on what bar was clicked in the chart
    // console.log(getElementAtEvent(chartRef.current, event));
  }

  return (
    <GraphWrapper>
      {title ? (
        <HeadingMedium style={{ marginBottom: '8px', fontSize: '20px' }}>
          {title}
        </HeadingMedium>
      ) : (
        <></>
      )}
      {subTitle ? (
        <HeadingSmallest color="textDefault" style={{ marginBottom: '18px' }}>
          {subTitle}
        </HeadingSmallest>
      ) : (
        <></>
      )}
      <div style={{ flexGrow: 1, width: '100%' }}>
        <Chart
          type="bar"
          ref={chartRef}
          options={opt}
          data={graphData}
          onClick={onClick}
        />
      </div>
    </GraphWrapper>
  )
}

type GraphData = {
  labels: string[]
  datasets: {
    id?: number
    type?: 'bar' | 'line'
    label: string
    data: number[]
    backgroundColor: string[] | string
    hoverBackgroundColor: string
    borderRadius: number
    borderColor?: string
    barThickness: number
    categoryPercentage: number
    minBarLength: number
    yAxisID?: string
  }[]
}

// takes a `YYYY-MM` string format and returns the MMM month format
const dateAbbrev = (date: string): string => {
  switch (date.substring(5, 7)) {
    case '01':
      return 'Jan'
    case '02':
      return 'Feb'
    case '03':
      return 'Mar'
    case '04':
      return 'Apr'
    case '05':
      return 'May'
    case '06':
      return 'Jun'
    case '07':
      return 'Jul'
    case '08':
      return 'Aug'
    case '09':
      return 'Sep'
    case '10':
      return 'Oct'
    case '11':
      return 'Nov'
    case '12':
      return 'Dec'
  }
  return date
}
