const Ball = ({ color, size }: { color: string; size: number }) => (
  <div
    style={{
      height: `${size}px`,
      width: `${size}px`,
      backgroundColor: color,
      borderRadius: '50%',
    }}
  />
)
export default Ball
