import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import QuickBooks from '../assets/logos/QuickBooks.png'
import Netsuite from '../assets/logos/Netsuite.png'
import Xero from '../assets/logos/Xero.png'
// import SageIntacct from '../assets/logos/SageIntacct.png'
import GoogleWorkspace from '../assets/logos/GoogleWorkspace.png'
import Microsoft365 from '../assets/logos/Microsoft365.png'
import Chrome from '../assets/logos/Chrome.png'
import Firefox from '../assets/logos/Firefox.png'
import Safari from '../assets/logos/Safari.png'
import Edge from '../assets/logos/Edge.jpeg'
import GreenCheckCircle from '../assets/GreenCheckCircle'
import Divider from '../components/Divider'
import { HeadingMedium } from '../components/Text'
import theme from '../Theme'
import { completeQuickbooksSetup, QuickbooksModalHandler } from './quickbooks'
import { completeXeroSetup, XeroModalHandler } from './xero'
import { NetsuiteModalHandler } from './netsuite'
import { GoogleWorkspaceModalHandler } from './google_workspace'
import { completeMicrosoftSetup, MicrosoftModalHandler } from './microsoft365'
import Loading from '../assets/Loading'
import { useMergeLink } from '@mergeapi/react-merge-link'
import GearIcon from '../assets/GearIcon'
import { backendRequest, addImpersonationParams } from '../utils/utils'
import { showToast, ToastType } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { useFlags } from 'launchdarkly-react-client-sdk'

const IntegrationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  width: '310px',
  padding: '24px',
})

const CardsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '25px',
  margin: '28px 0 40px',
})

const CardsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '25px',
})

const LinkButton = styled('button')({
  border: 'none',
  backgroundColor: theme.color.white,
  color: theme.color.textPurple,
  fontWeight: 500,
  padding: '10px',
  cursor: 'pointer',
})

const ImageWrapper = styled('div')({
  border: `1px solid ${theme.color.border}`,
  borderRadius: '4px',
  height: '68px',
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  marginBottom: '16px',
})

export enum IntgModal {
  NoModal = 0,
  QuickbooksRedirect,
  QuickbooksSuccess,
  QuickbooksFail,
  QuickbooksRemove,
  GoogleWorkspaceRedirect,
  GoogleWorkspaceSuccess,
  GoogleWorkspaceFail,
  GoogleWorkspaceRemove,
  GoogleWorkspaceSettings,
  NetsuiteRemove,
  NetsuiteFail,
  XeroRedirect,
  XeroSuccess,
  XeroFail,
  XeroRemove,
  SageIntacctRemove,
  SageIntacctFail,
  MicrosoftRedirect,
  MicrosoftSuccess,
  MicrosoftFail,
  MicrosoftRemove,
  MicrosoftSettings,
}

export enum EmailFilterStrategy {
  PERMITTED_INBOXES = 'permitted_inboxes_only',
  FORBIDDEN_INBOXES = 'forbidden_inboxes',
  NO_FILTER = 'no_filter',
  DISABLED_INBOXES = 'disabled_inboxes',
}

type ConnectedIntegrations = {
  quickbooks: boolean
  netsuite: boolean
  xero: boolean
  sage_intacct: boolean
  google_workspace: boolean
  microsoft_365: boolean
  chrome_extension: boolean
  firefox_extension: boolean
  safari_extension: boolean
  edge_extension: boolean
}

const Integrations = () => {
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()
  const { microsoft365 } = useFlags()

  const [integrations, setIntegrations] = useState<
    ConnectedIntegrations | undefined
  >()
  const [showModal, setShowModal] = useState<IntgModal>(0)
  const [linkTokenNS, setLinkTokenNS] = useState('')
  // const [linkTokenSI, setLinkTokenSI] = useState('')

  const onClickQuickBooks = () => {
    if (integrations && integrations.quickbooks) {
      setShowModal(IntgModal.QuickbooksRemove)
      return
    }
    setShowModal(IntgModal.QuickbooksRedirect)
  }

  const onClickXero = () => {
    if (integrations && integrations.xero) {
      setShowModal(IntgModal.XeroRemove)
      return
    }
    setShowModal(IntgModal.XeroRedirect)
  }

  // NetSuite connection
  const getMergeLinkTokenNS = async () => {
    const netsuitePath = addImpersonationParams(
      `/integrations/create-link-token/netsuite`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )

    const resp = await backendRequest(netsuitePath, {
      method: 'POST',
    })
    setLinkTokenNS(resp.link_token)
  }

  // step 3: Send public_token to server which exchanges it with account_token
  const onSuccessNS = useCallback(
    (public_token: string) => {
      const netsuitePath = addImpersonationParams(
        `/integrations/account-token/${public_token}/netsuite`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      backendRequest(netsuitePath)
      // TODO: add a toast message
    },
    [reqOrgId, reqUserId, impersonate]
  )

  const { open: openNS } = useMergeLink({
    linkToken: linkTokenNS,
    onSuccess: onSuccessNS,
  })

  const onClickNetsuite = () => {
    if (integrations && integrations.netsuite) {
      setShowModal(IntgModal.NetsuiteRemove)
      return
    }
    // step 1: get link token for integration
    getMergeLinkTokenNS()
    // step 2: open modal for netsuite integration
    openNS()
  }
  // TODO: add SageIntacct integration and test before onboarding Sage customer
  // SageIntacct connection
  // const getMergeLinkTokenSI = async () => {
  //   const resp = await backendRequest(
  //     `/integrations/create-link-token/sage-intacct`,
  //     {
  //       method: 'POST',
  //     }
  //   )
  //   setLinkTokenSI(resp.link_token)
  // }

  // // step 3: Send public_token to server which exchanges it with account_token
  // const onSuccessSI = useCallback((public_token: string) => {
  //   backendRequest(`/integrations/account-token/${public_token}/sage_intacct`)
  //   // TODO: add a toast message
  // }, [])

  // const { open: openSI } = useMergeLink({
  //   linkToken: linkTokenSI,
  //   onSuccess: onSuccessSI,
  // })

  // const onClickSageIntacct = () => {
  //   if (integrations && integrations.sage_intacct) {
  //     setShowModal(IntgModal.SageIntacctRemove)
  //     return
  //   }

  //   getMergeLinkTokenSI()

  //   openSI()
  // }

  const onClickGoogleWorkspace = () => {
    if (integrations && integrations.google_workspace) {
      setShowModal(IntgModal.GoogleWorkspaceRemove)
      return
    }
    setShowModal(IntgModal.GoogleWorkspaceRedirect)
  }
  const onClickMicrosoft365 = () => {
    if (integrations && integrations.microsoft_365) {
      setShowModal(IntgModal.MicrosoftRemove)
      return
    }
    setShowModal(IntgModal.MicrosoftRedirect)
  }

  const onClickExtension = (extension: string) => {
    let extensionURL = ''
    switch (extension) {
      case 'chrome_extension':
        extensionURL =
          'https://chrome.google.com/webstore/detail/diminish-saas-usage-monit/bpkibljodllhlanokipjabibokdjdhch'
        break
      case 'firefox_extension':
        extensionURL =
          'https://addons.mozilla.org/en-US/firefox/addon/diminish-saas-usage-monitor/'
        break
      case 'safari_extension':
        extensionURL =
          'https://apps.apple.com/us/app/diminish-saas-usage-monitor/id6451277774'
        break
      case 'edge_extension':
        extensionURL =
          'https://microsoftedge.microsoft.com/addons/detail/diminish-saas-usage-mon/cdpokdafdmmjdghhccegdijejbllkbnl'
        break
    }
    window.open(extensionURL, '_blank')
  }
  const onClickGoogleWorkspaceSettings = () => {
    setShowModal(IntgModal.GoogleWorkspaceSettings)
  }

  const onClickMicrosoftSettings = () => {
    setShowModal(IntgModal.MicrosoftSettings)
  }

  useEffect(() => {
    if (!window.location.href.includes('/quickbooks')) {
      return
    }
    completeQuickbooksSetup(setShowModal, impersonate, reqOrgId, reqUserId)
  }, [impersonate, reqOrgId, reqUserId])

  useEffect(() => {
    if (!window.location.href.includes('/xero')) {
      return
    }
    completeXeroSetup(setShowModal, impersonate, reqOrgId, reqUserId)
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (window.location.href.includes('/microsoft365_user_inbox')) {
      completeMicrosoftSetup(
        setShowModal,
        'USER_INBOX',
        impersonate,
        reqOrgId,
        reqUserId
      )
      return
    }

    if (window.location.href.includes('/microsoft365_user')) {
      completeMicrosoftSetup(
        setShowModal,
        'USER',
        impersonate,
        reqOrgId,
        reqUserId
      )
      return
    }
  }, [reqOrgId, reqUserId, impersonate])

  useEffect(() => {
    if (integrations !== undefined) {
      return
    }

    const getIntegrations = async () => {
      const reqPath = addImpersonationParams(
        `/integrations`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )

      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch integration status`, ToastType.ERROR)
        return
      }
      setIntegrations(resp)
    }
    getIntegrations()
  }, [integrations, reqOrgId, reqUserId, impersonate])

  const dismissModal = () => {
    setShowModal(0)
    setIntegrations(undefined)
  }

  if (integrations === undefined) {
    return <Loading large />
  }

  return (
    <>
      <HeadingMedium color="textDefault" style={{ marginTop: '16px' }}>
        Accounting and Finance
      </HeadingMedium>

      <CardsContainer>
        <CardsWrapper>
          <IntegrationCard
            name="QuickBooks Online"
            src={QuickBooks}
            onClick={onClickQuickBooks}
            connected={integrations.quickbooks}
          />
        </CardsWrapper>
        <CardsWrapper>
          <IntegrationCard
            name="NetSuite"
            src={Netsuite}
            onClick={onClickNetsuite}
            connected={integrations.netsuite}
          />
        </CardsWrapper>
        <CardsWrapper>
          <IntegrationCard
            name="Xero"
            src={Xero}
            onClick={onClickXero}
            connected={integrations.xero}
          />
        </CardsWrapper>
        {/* <CardsWrapper>
          <IntegrationCard
            name="Sage Intacct"
            src={SageIntacct}
            onClick={onClickSageIntacct}
            connected={integrations.sage_intacct}
            large
          />
        </CardsWrapper> */}
      </CardsContainer>

      <HeadingMedium color="textDefault" style={{ marginTop: '16px' }}>
        Identity Providers
      </HeadingMedium>
      <CardsWrapper>
        <IntegrationCard
          name="Google Workspace"
          src={GoogleWorkspace}
          onClick={onClickGoogleWorkspace}
          onClickSettings={onClickGoogleWorkspaceSettings}
          connected={integrations.google_workspace}
          large
        />
        {microsoft365 && (
          <IntegrationCard
            name="Microsoft 365"
            src={Microsoft365}
            onClick={onClickMicrosoft365}
            onClickSettings={onClickMicrosoftSettings}
            connected={integrations.microsoft_365}
            large
          />
        )}
      </CardsWrapper>
      <HeadingMedium color="textDefault">Extension</HeadingMedium>
      <CardsWrapper>
        <IntegrationCard
          name="Chrome Extension"
          src={Chrome}
          onClick={() => onClickExtension('chrome_extension')}
          connected={integrations.chrome_extension}
        />
        <IntegrationCard
          name="Firefox Add-on"
          src={Firefox}
          onClick={() => onClickExtension('firefox_extension')}
          connected={integrations.firefox_extension}
        />
        <IntegrationCard
          name="Safari Extension"
          src={Safari}
          onClick={() => onClickExtension('safari_extension')}
          connected={integrations.safari_extension}
        />
        <IntegrationCard
          name="Edge Extension"
          src={Edge}
          onClick={() => onClickExtension('edge_extension')}
          connected={integrations.edge_extension}
        />
      </CardsWrapper>
      <QuickbooksModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <NetsuiteModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <XeroModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <GoogleWorkspaceModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
      <MicrosoftModalHandler
        modal={showModal}
        onDismiss={dismissModal}
        setModal={setShowModal}
      />
    </>
  )
}

const GearWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '10px',
})

const IntegrationCard = ({
  name,
  src,
  onClick,
  onClickSettings,
  connected,
  large,
}: {
  name: string
  src: string
  onClick?: () => void
  onClickSettings?: () => void
  connected?: boolean
  large?: boolean
}) => (
  <IntegrationWrapper>
    <GearWrapper>
      {onClickSettings ? <GearIcon onClick={onClickSettings} /> : <span />}
    </GearWrapper>
    <ImageWrapper>
      <img
        src={src}
        alt={`${name}-logo`}
        style={{
          width: large ? '173px' : '68px',
          height: large ? '23px' : '68px',
        }}
      />
    </ImageWrapper>
    <HeadingMedium color="textDefault">{name}</HeadingMedium>
    <Divider style={{ margin: '16px 0' }} />
    <ButtonWrapper>
      {connected ? <IntegratedBadge /> : <span />}
      {onClick ? (
        <LinkButton onClick={onClick}>
          {connected ? 'Remove' : 'Add'}
        </LinkButton>
      ) : (
        <span />
      )}
    </ButtonWrapper>
  </IntegrationWrapper>
)

const IntegratedBadge = () => (
  <BadgeWrapper>
    Integrated
    <GreenCheckCircle
      transform="scale(0.7)"
      style={{ width: '14px', height: '14px' }}
    />
  </BadgeWrapper>
)

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BadgeWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 25px;
  border-radius: 12px;
  padding: 0 12px;
  font-size: 16px;
  background-color: ${theme.color.successGreenBackground};
  color: ${theme.color.textDefault};
`

export default Integrations
