import styled from 'styled-components'
import theme from '../Theme'

export const CodeBlock = styled('div')`
  font-size: 12px;
  line-height: 20px;
  background-color: ${theme.color.backgroundSubdued};
  border: 1px solid ${theme.color.border};
  border-radius: 4px;
  padding: 4px;
  overflow-wrap: break-word;
`
