import { useEffect, useState } from 'react'
import { HeadingSmallest } from '../../components/Text'
import Divider from '../../components/Divider'
import {
  LoginCard,
  PageWrapper,
  PurpleLink,
  WelcomeText,
} from '../../components/LoginComponents'
import Button from '../../components/Button'
import { TextField } from '../../components/InputFields'
import OktaAuth from '@okta/okta-auth-js'
import useAuth from '../../hooks/useAuth'

export default function LoginOkta() {
  const [domain, setDomain] = useState<string>('')
  const [oktaSSOURL, setOktaSSOURL] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const { getOktaSSOURL } = useAuth()

  const getDomain = async () => {
    const [oktaSSOURL, err] = await getOktaSSOURL(domain)
    if (err) {
      setError(err)
      return
    }
    setOktaSSOURL(oktaSSOURL)
  }

  useEffect(() => {
    if (oktaSSOURL) {
      const oktaAuth = new OktaAuth({
        issuer: oktaSSOURL,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
        redirectUri: window.location.origin + '/login/callback',
      })
      oktaAuth.signInWithRedirect({ pkce: false, responseType: 'code' })
    }
  }, [oktaSSOURL])

  return (
    <PageWrapper>
      <LoginCard>
        <WelcomeText>Welcome to Diminish</WelcomeText>
        <Divider style={{ margin: '16px 0' }} />
        <HeadingSmallest color="textSubdued" style={{ marginBottom: '24px' }}>
          Enter your domain to log in
        </HeadingSmallest>
        <TextField
          label="Domain"
          type="text"
          name="domain"
          placeholder="Enter your domain"
          onChange={(value) => setDomain(value)}
          marginBottom="32px"
          errorMsg={error || ''}
        />
        <PurpleLink href="/login">Log in without SSO</PurpleLink>
        <Button disabled={domain.length === 0} onClick={getDomain}>
          Next
        </Button>
      </LoginCard>
    </PageWrapper>
  )
}
