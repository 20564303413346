import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import ContractsEmpty from '../assets/ContractsEmpty'
import Loading from '../assets/Loading'
import Button from '../components/Button'
import { ContractEdit, ContractModal, ContractModalType } from './ContractModal'
import {
  ApplicationCell,
  ArrowDownCell,
  AutoRenewCell,
  ContractStatusCell,
  OwnerCell,
  SortArrow,
  SubTable,
  SubTableCell,
  SubTableHead,
  SubTableHeading,
  SubTableRow,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  TitleCell,
} from '../components/Table'
import {
  HeadingMedium,
  HeadingSmall,
  HeadingSmallest,
} from '../components/Text'
import theme from '../Theme'
import {
  currencyFormat,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import { UserType } from '../Users'
import { PDFPreviewModal } from '../Overview/LatestInvoicesTable'
import useImpersonation from '../hooks/useImpersonation'
import Paper from '../assets/Paper'
import Archive from '../assets/Archive'
import Pencil from '../assets/Pencil'
import Dropdown from '../components/Dropdown'
import { ProductDetailsType } from '../Overview/Product'

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  position: 'sticky',
  top: '77px',
  background: theme.color.backgroudDefault,
  zIndex: 5,
  alignItems: 'flex-end',
  width: '100%',
  padding: '24px 0',
})

type ContractRenewal = {
  id: number
  saas_id: number
  name: string
  logo_url: string
  plan_name: string
  document_type: string[]
  owner: string
  owner_hash: string
  profile_picture: string
  num_visits: number
  num_users: number
  billing_cycle: string
  contract_value: number
  start_date: string
  end_date: string
  department: string
  paid_licenses: string
  presigned_url: string
  auto_renew: boolean | null
  status: string
}

type Contracts = {
  upcoming_contracts: { [saas_id: number]: ContractRenewal[] }
  num_contracts: number
}

const UpcomingContractsPage = () => {
  const dropdownOptions = [
    'Next 30 Days',
    'Next 60 Days',
    'Next 90 Days',
    'Next 6 Months',
    'Next Year',
  ]
  const [filter, setFilter] = useState('Next 6 Months')
  const [contracts, setContracts] = useState<Contracts | null>()
  const [contractEdit, setContractEdit] = useState<ContractEdit | null>(null)
  const [showModal, setShowModal] = useState(false)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (showModal) {
      return
    }
    const getContracts = async () => {
      const dateFilter = filter.replaceAll(' ', '_').toLowerCase()
      const reqPath = addImpersonationParams(
        `/contract_renewals?filter=${dateFilter}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )

      const resp = await backendRequest(reqPath)
      if (resp === null || resp.error) {
        showToast(
          `Failed to fetch contracts. Please try again later.`,
          ToastType.ERROR
        )
        setContracts(null)
        return
      }
      setContracts(resp)
    }
    getContracts()
  }, [showModal, filter, reqOrgId, reqUserId, impersonate])

  if (contracts === undefined) {
    return <Loading large />
  }

  const onDismiss = () => {
    setShowModal(false)
  }

  const handleContractEdit = (contract_id: number) => {
    let contract = null
    for (const [, value] of Object.entries(
      contracts?.upcoming_contracts || []
    )) {
      contract = value.find((c) => c.id === contract_id)
      if (contract) {
        break
      }
    }
    if (contract) {
      setContractEdit({
        contract_id: contract.id,
        application_name: contract.name,
        owner_email: atob(contract.owner_hash),
        plan_name: contract.plan_name,
        billing_cycle: contract.billing_cycle,
        contract_value: contract.contract_value,
        start_date: contract.start_date,
        end_date: contract.end_date,
        num_seats: Number(contract.paid_licenses),
        document_type: contract.document_type,
      })
      setShowModal(true)
    }
  }

  const contractEditCallback = (contract: ContractEdit) => {
    setContracts(null)
  }

  return (
    <>
      <HeadingSmallest>
        ONLY contracts with end dates will appear here. If there's no END DATE,
        please add the end date to the contract you would like to appear in the
        upcoming renewal tab.
      </HeadingSmallest>
      <ButtonWrapper>
        <HeadingMedium color="textDefault">
          Upcoming Renewals
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {` (${contracts?.num_contracts || 0})`}
          </span>
        </HeadingMedium>
        <Dropdown
          options={dropdownOptions}
          defaultValue={filter}
          onSelect={(selected) => setFilter(selected)}
        />
      </ButtonWrapper>
      <UpcomingContracts
        onClick={() => setShowModal(true)}
        handleContractEdit={handleContractEdit}
        contractList={contracts?.upcoming_contracts || []}
      />
      {showModal && (
        <ContractModal
          modalType={
            contractEdit ? ContractModalType.EDIT : ContractModalType.CREATE
          }
          contractEdit={contractEdit}
          onDismiss={onDismiss}
          contractEditCallback={contractEditCallback}
        />
      )}
    </>
  )
}

const EmptyStateWrapper = styled('div')({
  borderTop: `1px solid ${theme.color.border}`,
  borderBottom: `1px solid ${theme.color.border}`,
  margin: '15px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  gap: '8px',
})

const UpcomingContractsEmptyState = ({ onClick }: { onClick: () => void }) => (
  <TableRow>
    <TableCell colSpan={9}>
      <EmptyStateWrapper>
        <ContractsEmpty />
        <HeadingSmall color="textDefault">
          This is where you see your upcoming contract renewals
        </HeadingSmall>
        <Button style={{ marginTop: '16px' }} onClick={onClick}>
          Add Contracts
        </Button>
      </EmptyStateWrapper>
    </TableCell>
  </TableRow>
)

export const UpcomingContracts = ({
  contractList,
  onClick,
  handleContractEdit,
}: {
  contractList: { [saas_id: number]: ContractRenewal[] }
  onClick: () => void
  handleContractEdit: (contract_id: number) => void
}) => {
  const [contracts, setContracts] = useState<{
    [saas_id: number]: ContractRenewal[]
  }>(contractList)
  const [users, setUsers] = useState<UserType[] | undefined>(undefined)
  const [showPDFPreviewModal, setShowPDFPreviewModal] = useState(false)
  const [invoiceURL, setInvoiceURL] = useState('')
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  useEffect(() => {
    if (!contractList || Object.keys(contractList).length === 0) {
      return
    }
    setContracts(contractList)
  }, [contractList])

  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        `/user_names`,
        impersonate,
        reqOrgId,
        reqUserId,
        false
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          `Failed to fetch users. Please try again later.`,
          ToastType.ERROR
        )
        setUsers([])
      }
      setUsers(resp)
    }
    getUsers()
  }, [reqOrgId, reqUserId, impersonate])

  const handleArchive = async (contract_id: number) => {
    const contractArchivePath = addImpersonationParams(
      `/contracts/archive`,
      impersonate,
      reqOrgId,
      reqUserId,
      false
    )
    const resp = await backendRequest(contractArchivePath, {
      method: 'POST',
      body: JSON.stringify({
        contract_id,
      }),
    })

    if (resp.error) {
      showToast(
        `Failed to archive contract. Please try again later.`,
        ToastType.ERROR
      )
      return
    }
    showToast(`Contract successfully archived`, ToastType.SUCCESS)
    const updatedContracts = { ...contracts }
    for (const [key, value] of Object.entries(updatedContracts)) {
      updatedContracts[parseInt(key)] = value.filter(
        (contract) => contract.id !== contract_id
      )
    }
    setContracts(updatedContracts)
  }

  const handlePDFPreview = (invoice_url: string) => {
    setInvoiceURL(invoice_url)
    setShowPDFPreviewModal(true)
  }

  return (
    <>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow
            style={{
              position: 'sticky',
              top: '175px',
              background: theme.color.backgroudDefault,
              zIndex: 3,
            }}
          >
            <TableCell mw="5px"> </TableCell>
            <TableCell>Application</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell w="200px">Owner</TableCell>
            <TableCell>Contract Value</TableCell>
            <TableCell>Auto-Renew</TableCell>
            <TableCell>Status</TableCell>
            <TableCell rightAlign={true} mw="110px">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(contracts).length === 0 ? (
            <UpcomingContractsEmptyState onClick={onClick} />
          ) : (
            <UpcomingContractRows
              contracts={contracts}
              users={users || []}
              handleContractEdit={handleContractEdit}
              handleArchive={handleArchive}
              handlePDFPreview={handlePDFPreview}
            />
          )}
        </TableBody>
      </TableWrapper>
      {showPDFPreviewModal !== false && (
        <PDFPreviewModal
          onDismiss={() => setShowPDFPreviewModal(false)}
          invoice_url={invoiceURL}
        />
      )}
      <Tooltip anchorSelect=".edit-icon" place="top" style={{ zIndex: 10 }}>
        Edit Contract
      </Tooltip>
      <Tooltip anchorSelect=".preview-icon" place="top" style={{ zIndex: 10 }}>
        Preview Contract
      </Tooltip>
      <Tooltip anchorSelect=".archive-icon" place="top" style={{ zIndex: 10 }}>
        Archive Contract
      </Tooltip>
    </>
  )
}

const UpcomingContractRows = ({
  contracts,
  users,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  contracts: { [saas_id: number]: ContractRenewal[] }
  users: UserType[]
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (invoice_url: string) => void
}) => {
  if (Object.keys(contracts).length === 0) {
    return <UpcomingContractsEmptyState onClick={() => {}} />
  }

  return Object.values(contracts).map((contractList, i) => (
    <UpcomingContractRow
      key={i}
      contracts={contractList || []}
      users={users}
      handleContractEdit={handleContractEdit}
      handleArchive={handleArchive}
      handlePDFPreview={handlePDFPreview}
    />
  ))
}

const UpcomingContractRow = ({
  contracts,
  users,
  handleContractEdit,
  handleArchive,
  handlePDFPreview,
}: {
  contracts: ContractRenewal[]
  users: UserType[]
  handleContractEdit: (contract_id: number) => void
  handleArchive: (contract_id: number) => void
  handlePDFPreview: (invoice_url: string) => void
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const saas_id = contracts.length ? contracts[0].saas_id : 0
  const contract = contracts ? contracts[0] : undefined

  if (!contract) {
    return null
  }

  return (
    <>
      <TableRow alert={contracts.length === 0}>
        <ArrowDownCell
          onClick={() => setShowDetails(!showDetails)}
          open={showDetails}
        />
        <ApplicationCell
          id={saas_id}
          logo={contract.logo_url}
          name={contract.name || ''}
          number={contracts.length || 0}
          error={contracts.length === 0 ? 'Missing Contract' : undefined}
        />
        <TableCell>{contract.start_date || '-'}</TableCell>
        <TableCell>{contract.end_date || '-'}</TableCell>
        <OwnerCell
          appId={saas_id}
          options={users}
          defaulOption={contract.owner || ''}
        />
        <TableCell>
          {contract?.contract_value === undefined
            ? '-'
            : `$${currencyFormat(contract.contract_value)}`}
        </TableCell>
        <AutoRenewCell
          appID={contract.saas_id}
          defaultValue={contract.auto_renew}
        />
        <ContractStatusCell
          appID={contract.saas_id}
          defaultValue={contract.status}
        />
        <TableCell mw="110px" rightAlign={true}>
          <Pencil
            className="edit-icon"
            onClick={() => handleContractEdit(contract?.id || 0)}
          />
          {contract && contract.presigned_url ? (
            <Paper
              style={{ marginLeft: '12px' }}
              className="preview-icon"
              onClick={() => handlePDFPreview(contract.presigned_url)}
            />
          ) : null}
          {contract ? (
            <Archive
              className="archive-icon"
              style={{ marginLeft: '12px' }}
              onClick={() => handleArchive(contract.id)}
            />
          ) : null}
        </TableCell>
      </TableRow>
      {showDetails && (
        <>
          <ContractMoreDetail contract={contract} />
          <ContractsAllPerTool
            contracts={contracts}
            handleContractEdit={handleContractEdit}
            handlePDFPreview={handlePDFPreview}
            handleArchive={handleArchive}
          />
        </>
      )}
    </>
  )
}

const ContractMoreDetail = ({ contract }: { contract?: ContractRenewal }) => {
  const [details, setDetails] = useState<
    ProductDetailsType | null | undefined
  >()

  useEffect(() => {
    if (!contract) {
      return
    }
    const getContractDetails = async () => {
      const reqPath = `/product/${contract.saas_id}`
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(
          `Failed to fetch contract details. Please try again later.`,
          ToastType.ERROR
        )
        setDetails(null)
        return
      }
      setDetails(resp)
    }
    getContractDetails()
  }, [contract])

  if (!contract || !details) {
    return (
      <SubTable>
        <SubTableCell style={{ height: '50px' }} colSpan={15}>
          <Loading />
        </SubTableCell>
      </SubTable>
    )
  }

  return (
    <SubTable>
      <SubTableCell colSpan={15}>
        <table cellSpacing={0} style={{ width: '100%' }}>
          <tbody>
            <SubTableRow>
              <TitleCell>More Detail</TitleCell>
              <TableCell>
                <table cellSpacing={0} style={{ width: '100%' }}>
                  <SubTableHead>
                    <SubTableRow>
                      <SubTableHeading>Plan</SubTableHeading>
                      <SubTableHeading>Department</SubTableHeading>
                      <SubTableHeading>Paid Licenses</SubTableHeading>
                      <SubTableHeading>Active Users</SubTableHeading>
                      <SubTableHeading>Total Visits</SubTableHeading>
                      <SubTableHeading
                        style={{ width: '35px' }}
                      ></SubTableHeading>
                    </SubTableRow>
                  </SubTableHead>
                  <tbody>
                    <SubTableRow>
                      <TableCell>{contract?.plan_name || '-'}</TableCell>
                      <TableCell>{contract?.department || '-'}</TableCell>
                      <TableCell>{contract?.paid_licenses || 'N/A'}</TableCell>
                      <TableCell>{details.num_users || '-'}</TableCell>
                      <TableCell>{details.num_visits || '-'}</TableCell>
                      <SubTableCell style={{ width: '35px' }}></SubTableCell>
                    </SubTableRow>
                  </tbody>
                </table>
              </TableCell>
            </SubTableRow>
          </tbody>
        </table>
      </SubTableCell>
    </SubTable>
  )
}
const ContractsAllPerTool = ({
  contracts,
  handleContractEdit,
  handlePDFPreview,
  handleArchive,
}: {
  contracts: ContractRenewal[]
  handleContractEdit: (contract_id: number) => void
  handlePDFPreview: (invoice_url: string) => void
  handleArchive: (contract_id: number) => void
}) =>
  contracts &&
  contracts.length > 1 && (
    <SubTable>
      <SubTableCell colSpan={15}>
        <table cellSpacing={0} style={{ width: '100%' }}>
          <tbody>
            <SubTableRow>
              <TitleCell>
                All Contracts
                <span
                  style={{ fontWeight: '400', fontSize: '14px' }}
                >{` (${contracts.length})`}</span>
              </TitleCell>
              <TableCell>
                <table cellSpacing={0} style={{ width: '100%' }}>
                  <SubTableHead>
                    <SubTableRow>
                      <SubTableHeading>Plan</SubTableHeading>
                      <SubTableHeading>Contract Value</SubTableHeading>
                      <SubTableHeading>Start Date</SubTableHeading>
                      <SubTableHeading
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100px',
                        }}
                      >
                        <span>End Date</span>
                        <SortArrow />
                      </SubTableHeading>
                      <SubTableHeading
                        style={{ width: '110px' }}
                      ></SubTableHeading>
                    </SubTableRow>
                  </SubTableHead>
                  <tbody>
                    {contracts &&
                      contracts.map((c, i) => (
                        <SubTableRow key={i}>
                          <TableCell>{c.plan_name || '-'}</TableCell>
                          <TableCell>
                            {c.contract_value === undefined
                              ? '-'
                              : `$${currencyFormat(c.contract_value)}`}
                          </TableCell>
                          <TableCell>{c.start_date || '-'}</TableCell>
                          <TableCell>{c.end_date || '-'}</TableCell>
                          <TableCell
                            mw="110px"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: '12px',
                            }}
                          >
                            <Pencil
                              className="edit-icon"
                              onClick={() => handleContractEdit(c.id)}
                            />
                            {c && c.presigned_url ? (
                              <Paper
                                onClick={() =>
                                  handlePDFPreview(c.presigned_url)
                                }
                              />
                            ) : null}
                            <Archive onClick={() => handleArchive(c.id)} />
                          </TableCell>
                          <SubTableCell
                            style={{ width: '15px' }}
                          ></SubTableCell>
                        </SubTableRow>
                      ))}
                  </tbody>
                </table>
              </TableCell>
            </SubTableRow>
          </tbody>
        </table>
      </SubTableCell>
    </SubTable>
  )

export default UpcomingContractsPage
