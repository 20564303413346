import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../assets/Loading'
import Dropdown from '../components/Dropdown'
import {
  EmptyTableBody,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
  UserCell,
} from '../components/Table'
import { HeadingMedium } from '../components/Text'
import {
  getPastNMonths,
  backendRequest,
  addImpersonationParams,
} from '../utils/utils'
import { ToastType, showToast } from '../utils/toastify'
import useImpersonation from '../hooks/useImpersonation'
import { GhostButton } from '../components/Button'
import DownloadIcon from '../assets/Download'

type User = {
  hash: string
  first_name: string
  last_name: string
  profile_picture: string
  email: string
  access_level: string
  frequency: number
}

export const ProductDetailsTable = () => {
  const [users, setUsers] = useState<User[] | null>(null)
  const [monthsAgo, setMonthsAgo] = useState<number>(0)
  const monthOptions = getPastNMonths(12)
  const { reqOrgId, reqUserId, impersonate } = useImpersonation()

  const onRangeChange = (selected: string) => {
    setMonthsAgo(monthOptions.indexOf(selected))
  }
  let { product_id } = useParams()
  useEffect(() => {
    const getUsers = async () => {
      const reqPath = addImpersonationParams(
        `/product/${product_id}/usage?monthsAgo=${monthsAgo}`,
        impersonate,
        reqOrgId,
        reqUserId,
        true
      )
      const resp = await backendRequest(reqPath)
      if (resp.error) {
        showToast(`Failed to fetch product usage`, ToastType.ERROR)
        setUsers([])
        return
      }
      const sortedUsers = (resp as User[]).sort(
        (a, b) => b.frequency - a.frequency
      )
      setUsers(sortedUsers)
    }
    getUsers()
  }, [product_id, monthsAgo, reqOrgId, reqUserId, impersonate])

  const downloadUsage = () => {
    if (!users) return
    const csv = users.map((user) => {
      return `"${user.first_name}","${user.last_name}","${user.email}","${user.frequency}"`
    })
    csv.unshift(`First Name,Last Name,Email Address,Page Visits`)

    const csvData = csv.join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `diminish-usage_by_user-${new Date().toISOString()}.csv`
    a.click()
  }

  if (users === null) {
    return <Loading />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          margin: '24px 0 16px',
        }}
      >
        <HeadingMedium color="textDefault" style={{ marginBottom: '15px' }}>
          Usage by User
          <span style={{ fontWeight: '400', fontSize: '14px' }}>
            {users.length > 0 && ` (${users.length})`}
          </span>
        </HeadingMedium>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Dropdown
            onSelect={onRangeChange}
            options={monthOptions}
            defaultValue={monthOptions[0]}
          />
          <GhostButton onClick={downloadUsage} style={{ padding: '12px' }}>
            <DownloadIcon />
          </GhostButton>
        </div>
      </div>
      <TableWrapper cellSpacing={0}>
        <TableHead>
          <TableRow>
            <TableCell w="40%">User</TableCell>
            <TableCell>Access Level</TableCell>
            <TableCell>Visits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length === 0 ? (
            <EmptyTableBody colSpan={3} />
          ) : (
            users.map((user, i) => (
              <TableRow key={i}>
                <UserCell
                  hash={user.hash}
                  first_name={user.first_name}
                  last_name={user.last_name}
                  email={user.email}
                  profile_picture={user.profile_picture}
                />
                <TableCell>{user.access_level}</TableCell>
                <TableCell>{user.frequency}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </TableWrapper>
    </>
  )
}
