import React, { useEffect, useRef, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'
import theme from '../Theme'
import ArrowDropDown from '../assets/ArrowDropDown'

export const Select = styled('button')<{
  isOpen: boolean
  disabled: boolean | undefined
}>`
  border-radius: 5px;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? theme.color.borderDisabled : theme.color.border};
  color: 1px solid ${theme.color.textDefault};
  min-width: 200px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    ${({ disabled }) =>
      disabled ? '' : `border: 1px solid ${theme.color.textPurple};`};
  }
  & > svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
  }
  & > * {
    pointer-events: none;
  }
`

export const OptionsWrapper = styled('div')({
  marginTop: '2px',
  position: 'absolute',
  backgroundColor: theme.color.white,
  outline: 'none',
  '-webkit-appearance': 'none',
  zIndex: 10,
  borderRadius: '5px',
  maxHeight: '250px',
  overflowY: 'scroll',
  overflowX: 'hidden',
  width: '100%',
  border: `1px solid ${theme.color.border}`,
  scrollbarWidth: 'thin', // firefox
  '-ms-overflow-style': 'auto' /* IE and Edge */,
  '::-webkit-scrollbar': {
    width: '5px', // < 121 chrome; for 121+ chrome takes in scrollbarWidth above
  },
})

export const Option = styled('button')({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  backgroundColor: theme.color.white,
  cursor: 'pointer',
  color: theme.color.textSubdued,
  height: '44px',
  padding: '12px 16px',
  width: '100%',
  textAlign: 'left',
  '&: hover': {
    backgroundColor: theme.color.backgroundHover,
  },
})

const OptionSeparator = styled('div')({
  width: '100%',
  borderBottom: `2px solid ${theme.color.textPurple}`,
})

const Dropdown = ({
  label,
  placeholder,
  options,
  defaultValue,
  onSelect,
  disabled,
  marginBottom,
  style,
}: {
  label?: string
  placeholder?: string
  options: string[] | string[][]
  defaultValue?: string | null
  onSelect: (selected: string) => void
  disabled?: boolean
  marginBottom?: string
  style?: CSSProperties
}) => {
  const ref = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(
    defaultValue || null
  )

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target !== ref.current) {
        setShowOptions(false)
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [showOptions])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: marginBottom,
        ...style,
      }}
    >
      <label
        style={{
          color: disabled ? theme.color.textSubdued : theme.color.textDefault,
          fontSize: '12px',
        }}
        onClick={() => setShowOptions(!showOptions)}
      >
        {label}
      </label>
      <Select
        disabled={disabled}
        ref={ref}
        isOpen={showOptions}
        onClick={() => !disabled && setShowOptions(!showOptions)}
      >
        {selectedOption ? (
          selectedOption
        ) : (
          <span style={{ color: theme.color.textSubdued }}>{placeholder}</span>
        )}
        {!disabled && <ArrowDropDown />}
      </Select>
      {showOptions && (
        <div style={{ position: 'relative' }}>
          <OptionsWrapper>
            {options.map((option, i) => (
              <div key={i}>
                {Array.isArray(option) ? (
                  <>
                    {option.map((o, j) => (
                      <Option
                        key={i * 100 + j}
                        onClick={() => {
                          onSelect(o)
                          setSelectedOption(o)
                          setShowOptions(false)
                        }}
                      >
                        {o}
                      </Option>
                    ))}
                    {i !== options.length - 1 && <OptionSeparator />}
                  </>
                ) : (
                  <Option
                    key={i}
                    onClick={() => {
                      onSelect(option)
                      setSelectedOption(option)
                      setShowOptions(false)
                    }}
                  >
                    {option}
                  </Option>
                )}
              </div>
            ))}
          </OptionsWrapper>
        </div>
      )}
    </div>
  )
}
export default Dropdown
