// this file is shared by `extension` and `web-ui`. Changes to this file will affect both services
const color = {
  textDefault: '#3A393B',
  textSubdued: '#78767A',
  textPurple: '#6011B1',
  textBlue: '#0071EB',
  textError: '#E01E5A',
  textSuccess: '#02AB4B',
  backgroudDefault: '#FAFAFA',
  backgroundSubdued: '#F5F4F5',
  backgroundError: '#FEF6F8',
  backgroundSuccess: '#F7FCFA',
  backgroundPurple: '#302047',
  iconHover: '#EFE7F7',
  iconClick: '#C7B7D7',
  backgroundHover: '#F7F3FB',
  btnBgDisabled: '#EEE',
  btnTxtDisabled: '#969499',
  border: '#B8B0C0',
  borderDisabled: '#D7D3DA',
  white: '#fff',
  successGreen: '#02AB4B',
  errorRed: '#E01E5A',
  warningYellow: '#C28B00',
  successGreenBackground: '#E5F5E9',
  graphBarDefault: '#D9D9D9',
  graphBarHover: '#3E0B70',
  graphPurple: '#B372C1',
  graphPurpleHover: '#A256B3',
  graphGreen: '#62BAAC',
  graphGreenHover: '#48AA9A',
  graphLineBlue: '#6271BA',
  graphBarDisabled: '#F0F0F0',
  ChipGreen: '#02893C',
  ChipYellow: '#9B6F00',
  ChipRed: '#B31848',
  StatusRed: '#CC4747',
}
const theme = {
  color,
  size: {},
}

export default theme

export type Theme = typeof theme
